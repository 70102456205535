import React from 'react';
import { FiInstagram, FiLinkedin, FiYoutube } from 'react-icons/fi';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";


// import Down from './common/Down';
import { events } from '../resources/events';
import Down from './common/Down';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { BsLinkedin } from 'react-icons/bs';

const iconStyle = {
  fontSize: '24px',
};

const ButtonStyleLeft = 'swiper-button-prev hidden absolute top-[35%] left-12 w-12 h-12 rounded-full shadow-xl bg-red-500 lg:block text-red-500';
const ButtonStyleRight = 'swiper-button-next hidden absolute top-[35%] right-12 w-12 h-12 rounded-full shadow-xl bg-red-500 lg:block text-red-500 pl-4';

const Events = () => {
  return (
    <>
      <section id='Events' className='mb-14 bg-white relative'>
        <div className={ ButtonStyleLeft }></div> <FaChevronLeft className='text-white absolute pointer-events-none top-[34.3%] left-[3.8rem] z-10 text-[20px] hidden lg:block'/>
        <div className={ ButtonStyleRight }></div> <FaChevronRight className='text-white absolute pointer-events-none top-[34.3%] right-[3.8rem] z-10 text-[20px] hidden lg:block'/>
        <div className=' text-center lg:mx-20 px-4 py-10 sm:px-6 sm:py-14 lg:px-8'>
          <h2 className='text-center text-gray-700 text-4xl mb-10 font-bold tracking-tight sm:text-5xl'>
            Events
          </h2>
          <Swiper
            className='pb-10'
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            spaceBetween={50}
          >
            <div className='swiper-container mt-4 pb-2 !overflow-hidden'>
              <div className='swiper-wrapper'>
                {events.map((event, i) => (
                  <SwiperSlide key={i}>
                    <div className='flex justify-center'>
                      <div className='rounded-lg shadow-lg bg-white max-w-sm'>
                        <img
                          className='rounded-t-lg'
                          src={event.image}
                          alt={event.name}
                        />
                        <div className='px-6 py-4 h-64 relative'>
                          <div className='flex justify-end'>
                            {event.live ? (
                              <div className='rounded-md bg-green-400 py-1 -mt-1 -mr-2 mb-2 w-[20%] text-sm text-center'>
                                <span className='font-semibold text-blue-900'>
                                  Live
                                </span>
                              </div>
                            ) : (
                              <div className='rounded-md bg-red-500 py-1 -mt-1 -mr-2 mb-2 w-[20%] text-sm text-center'>
                                <span className='font-semibold text-white'>
                                  Over
                                </span>
                              </div>
                            )}
                          </div>
                          <h5 className='text-gray-900 text-xl font-bold mb-2'>
                            {event.name}
                          </h5>
                          <p className='text-gray-700 text-base mb-4'>
                            {event.content}
                          </p>
                          <div className='flex justify-between absolute bottom-4 w-full'>
                            <div className='flex'>
                              {event.instaLink && (
                                <a
                                  href={event.instaLink}
                                  target='_blank'
                                  className='px-2 text-blue-500 custom-hover'
                                >
                                  <FiInstagram style={iconStyle} />
                                </a>
                              )}
                              {event.youtubeLink && (
                                <a
                                  href={event.youtubeLink}
                                  target='_blank'
                                  className='px-2 text-blue-500 custom-hover'
                                >
                                  <FiYoutube style={iconStyle} />
                                </a>
                              )}
                              {event.linkdinLink && (
                                <a
                                  href={event.linkdinLink}
                                  target='_blank'
                                  className='px-2 text-blue-500 custom-hover'
                                >
                                  <FiLinkedin style={iconStyle} />
                                </a>
                              )}
                            </div>
                            {event.summary && (
                                <a
                                  href={event.summary}
                                  target='_blank'
                                  className='px-2 text-blue-500 custom-hover px-[60px]'
                                >
                                  Learn More
                                </a>
                            )}
                            {event.link && event.live ? (
                              <a
                                href={event.link}
                                target='_blank'
                                className='flex items-center text-blue-600'
                              >
                                Register now&nbsp;
                                <HiOutlineArrowRight className='text-blue-600' />
                              </a>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </div>
          </Swiper>
          <Down href='testimonials' />
        </div>
      </section>
      )
    </>
  );
};

export default Events;
