import React from 'react';
import AboutSection from '../AboutSection';

const About = () => {
  return (
    <>
      <AboutSection />
    </>
  );
};

export default About;
