import React, { useEffect, useState } from 'react';
import { FiInstagram } from 'react-icons/fi';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { BsLinkedin, BsYoutube } from 'react-icons/bs';
import { BiCalendar } from "react-icons/bi"
import { HiOutlineUserGroup, HiOutlineInformationCircle } from "react-icons/hi"
import { useNavigate } from 'react-router-dom';
import { logoPng } from '../assets';

const iconStyle = {
  fontSize: '24px',
};

const menuIconStyle = {
  fontSize: '22px',
  marginTop: '2px'
}

export const navigationLeft = [
  {
    name: 'Events',
    href: 'Events',
    ping: false,
    path: '/',
    active: false,
  },
  { 
    name: 'Team', 
    href: '', 
    path: '/team', 
    ping: false, 
    active: false,
    icon: ( <HiOutlineUserGroup style={menuIconStyle}/> ),
  },
  {
    name: 'About',
    href: '',
    path: '/about',
    ping: false,
    active: false,
    icon: ( <HiOutlineInformationCircle style={menuIconStyle} /> ),
  },
  {
    name: 'Activities',
    href: '',
    path: '/activities',
    ping: true,
    active: false,
    icon: ( <BiCalendar style={menuIconStyle} /> ),
  },
  // { name: 'Testimonials', href: '#testimonials', path: '/', ping: true, active: false },
  // { name: 'Collaborators', href: '', path: '/collaborators', ping: true, active: false },
];

export const setActiveNav = (pageName: string) => {
  navigationLeft.map((item) => {
    item.active = false;
    if (item.name == pageName) {
      item.active = true;
    }
  });
};

const navigationRight = [
  {
    icon: <FiInstagram style={iconStyle} />,
    href: 'https://instagram.com/iitm_diplomacia',
    ping: true,
  },
  {
    icon: <BsLinkedin style={{ fontSize: '22px' }} />,
    href: 'https://www.linkedin.com/company/diplomacia-iitm-bs',
    ping: true,
  },
  {
    icon: <BsYoutube style={iconStyle} />,
    href: 'https://www.youtube.com/@diplomacia_iitm',
    ping: true,
  },
  // { icon: <FiTwitter style={iconStyle} />, href: '#', ping: false },
];
const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isAbout, setIsAbout] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      [
        '/about',
        '/team',
        '/activities',
        // '/collaborators',
      ].includes(window.location.pathname) ||
      window.location.pathname.includes('cert')
    ) {
      setIsAbout(true);
    } else {
      setIsAbout(false);
    }

    if (window.location.pathname === '/' && window.location.hash === '') {
      setActiveNav('');
    }
  }, [window.location.pathname]);

  return (
    <nav
      className={`top-0 z-50 absolute w-full flex backdrop-blur-sm ${
        isAbout ? 'bg-primary transition-colors duration-100 ease-in-out' : ''
      } flex-wrap items-center justify-between px-2`}
    >
      <div className='container px-4 -my-2 mx-auto flex flex-wrap items-center justify-between'>
        <div
          className={`w-full ${
            mobileMenuOpen ? 'hidden' : ''
          } relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start`}
        >
          <a
            className='custom-gradient-2 text-xl font-bold leading-relaxed inline-block
            mr-4 py-2 whitespace-nowrap uppercase cursor-pointer'
            onClick={() => {
              setActiveNav('');
              navigate('/');
            }}
          >
            <span className='sr-only'>Diplomacia</span>
            <img src={logoPng} className='h-20' />
          </a>
          <div className='flex lg:hidden'>
            <button
              type='button'
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-primary-cta'
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className='sr-only'>Open main menu</span>
              <Bars3Icon className='h-10 w-10' aria-hidden='true' />
            </button>
          </div>
        </div>
        <div
          className='lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none hidden'
          id='example-navbar-warning'
        >
          <ul className='flex flex-col lg:flex-row list-none mr-auto'>
            <div className='relative ml-10 hidden lg:flex lg:gap-x-12'>
              {navigationLeft.map((item) => (
                <span
                  key={item.name}
                  className={`relative custom-hover inline-flex transition`}
                >
                  <a
                    onClick={() => {
                      if (item.path) {
                        item.name != 'Events'
                          ? setActiveNav(item.name)
                          : setActiveNav('');
                        navigate(item.path);
                      }
                      window.location.hash = item.href;
                    }}
                    className={`font-bold cursor-pointer flex transition-all ${
                      item.active ? 'bg-gray-500/20' : 'bg-gray-500/5'
                    } -mx-3 block rounded-lg py-2 px-3 mr-1 leading-7 text-primary-cta hover:bg-gray-500/20`}
                  >
                    {item.active ? item.icon : <></>}{' '}
                    <span
                      className={`block transition-all ${
                        item.active ? 'ml-[10px]' : ''
                      }`}
                    >
                      {' '}
                      {item.name}{' '}
                    </span>
                  </a>
                  {item.ping && (
                    <span className='flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1'>
                      <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75'></span>
                      <span className='relative inline-flex rounded-full h-3 w-3 bg-sky-500'></span>
                    </span>
                  )}
                </span>
              ))}
            </div>
          </ul>
          <ul className='flex flex-col lg:flex-row list-none lg:ml-auto'>
            <div className='relative ml-10 hidden lg:flex lg:gap-x-8'>
              {navigationRight.map((item, i) => (
                <span
                  key={i}
                  className='relative custom-hover bg-gray-500/10 rounded-lg py-2 px-2 inline-flex'
                >
                  <a
                    className='text-primary-cta'
                    href={item.href}
                    target='_blank'
                  >
                    {item.icon}
                  </a>
                  {item.ping && (
                    <span className='flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1'>
                      <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75'></span>
                      <span className='relative inline-flex rounded-full h-3 w-3 bg-sky-500'></span>
                    </span>
                  )}
                </span>
              ))}
            </div>
          </ul>
        </div>
      </div>
      <Dialog as='div' open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div
          className='absolute top-0 w-full object-none h-full bg-center'
          style={{
            backgroundImage: "url('https://i.imgur.com/otf9Hhc.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <span
            id='blackOverlay'
            className='w-full h-full absolute opacity-75 bg-black'
          ></span>
        </div>
        <Dialog.Panel className='fixed inset-0 z-10 overflow-y-auto px-6 py-6 lg:hidden'>
          <div className='flex items-center justify-between'>
            <a
              className='custom-gradient-2 text-xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase'
              onClick={() => {
                navigate('/'), setMobileMenuOpen(false);
              }}
            >
              <span className='sr-only'>Diplomacia</span>
              Diplomacia
            </a>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-primary-cta'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='flex flex-col items-center gap-2 space-y-2 py-6'>
                {navigationLeft.map((item) => (
                  <span
                    key={item.name}
                    className='relative custom-hover inline-flex'
                  >
                    <a
                      onClick={() => {
                        if (item.path) {
                          navigate(item.path);
                        }
                        window.location.hash = item.href;
                        setMobileMenuOpen(false);
                      }}
                      className='font-bold cursor-pointer -mx-3 block bg-gray-500/10 rounded-lg py-2 px-3 mr-1 leading-7 text-primary-cta hover:bg-gray-500/20'
                    >
                      {item.name}
                    </a>
                    {item.ping && (
                      <span className='flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1'>
                        <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75'></span>
                        <span className='relative inline-flex rounded-full h-3 w-3 bg-sky-500'></span>
                      </span>
                    )}
                  </span>
                ))}
              </div>
              <div className='py-6'>
                <a
                  href='#'
                  className='-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-400/10'
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </nav>
  );
};

export default Navbar;
