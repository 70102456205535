import React from 'react';
import { CertData } from './Certificate';
import { format, parseISO } from 'date-fns';
import { MdDateRange, MdOutlineEmojiEvents, MdVerified } from 'react-icons/md';
import { defaultImg } from '../assets';
import { FaUniversity } from 'react-icons/fa';
import QRCode from 'react-qr-code';

const CertProfile = ({ certData }: { certData: CertData }) => {
  const qrValue = 'https://iitmdiplomacia.in/cert/' + certData?.qrCodeSlang;
  return (
    <section className='pt-16 mt-20 bg-blueGray-50'>
      <div className='w-full lg:w-4/12 px-4 mx-auto'>
        <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16'>
          <div className='px-6'>
            <div className='flex flex-wrap justify-center'>
              <div className='w-full px-4 flex justify-center mb-20'>
                <div className='relative'>
                  <img
                    alt='...'
                    src={certData?.image ? certData?.image : defaultImg}
                    className='shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px'
                  />
                </div>
              </div>
            </div>
            <div className='text-center mt-12'>
              <h3 className='text-xl font-semibold leading-normal mb-2 text-blueGray-700'>
                {certData?.name}
              </h3>
              <div className='text-xl leading-normal flex justify-center items-center gap-1 mt-0 mb-2 text-blueGray-400 font-bold uppercase'>
                <MdOutlineEmojiEvents />
                {certData?.event}
              </div>
              <div className='mb-2 flex gap-2 items-center justify-center text-blueGray-600 mt-10'>
                <MdDateRange />
                {format(
                  parseISO(certData?.date.toLocaleString() as string),
                  'dd MMMM yyyy',
                )}
              </div>
              <div className='mb-2 flex gap-2 items-center justify-center text-blueGray-600 mt-5'>
                <FaUniversity />
                Indian Institute of Technology, Madras
              </div>
            </div>
            <div className='mt-10 py-10 border-t border-b border-blueGray-200 text-center'>
              <div className='flex flex-wrap justify-center'>
                <div className='w-full lg:w-9/12 px-4'>
                  <p className='mb-4 text-lg leading-relaxed text-blueGray-700'>
                    {certData?.description}
                  </p>
                </div>
              </div>
            </div>
            <div className='flex items-center gap-4 flex-col my-10'>
              <QRCode size={100} value={qrValue} />
              <div className='flex gap-2 items-center'>
                <MdVerified size={20} color='green' />{' '}
                <div className='italic'>Verified</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className='relative  pt-8 pb-6 mt-8'>
        <div className='container mx-auto px-4'>
          <div className='flex flex-wrap items-center md:justify-between justify-center'>
            <div className='w-full md:w-6/12 px-4 mx-auto text-center'>
              <div className='text-sm text-blueGray-500 font-semibold py-1'>
                Made with ❤️ by{' '}
                <a
                  href='https://iitmdiplomacia.in'
                  className='text-blueGray-500 hover:text-blueGray-800'
                  target='_blank'
                >
                  Diplomacia
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default CertProfile;
