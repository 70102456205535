import React from 'react';

const Down = ({ href, white = true }: { href: string; white?: boolean }) => {
  return (
    <div className='mt-14 mb-20 flex items-center justify-center gap-x-6'>
      <a href={`#${href}`}>
        <div className='text-center animate-bounce justify-center'>
          <div
            className={`${
              white ? 'bg-white' : ''
            } p-2 w-12 h-12 ring-1 ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center`}
          >
            <>

            <svg
              className='w-6 h-6 text-violet-500'
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path d='M19 14l-7 7m0 0l-7-7m7 7V3'></path>
            </svg>
            </>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Down;
