import React from 'react';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { studentTestimonials } from '../resources/testimonials';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const StudentTestimonials = () => {
  return (
    <div id='studentTestimonials' className='my-10'>
      <div className='text-center max-w-xl mx-auto'>
        <h1 className='text-4xl md:text-5xl font-bold mb-5 text-gray-600'>
          What Students <br />
          are saying.
        </h1>
        <div className='text-center mb-10'>
          <span className='inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1'></span>
          <span className='inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1'></span>
          <span className='inline-block w-40 h-1 rounded-full bg-indigo-500'></span>
          <span className='inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1'></span>
          <span className='inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1'></span>
        </div>
      </div>
      <Swiper
        className='pb-10'
        autoplay={{
          delay: 8000,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination, Autoplay]}
        spaceBetween={50}
        centeredSlides
        centeredSlidesBounds
      >
        {studentTestimonials.map((studentTestimonial, i) => (
          <SwiperSlide key={i}>
            <div className='flex flex-col max-w-md mx-4 my-6 shadow-lg'>
              <div className='px-4 py-12 rounded-t-lg sm:px-8 md:px-12'>
                <div className='flex justify-center'>
                  <div className='relative rounded-full py-1 px-2 w-[50%] text-base text-center leading-6 ring-1 ring-custBlue-40 hover:ring-custBlue'>
                    <span className='font-semibold text-primary'>
                      {studentTestimonial.event}
                    </span>
                  </div>
                </div>
                <p className='relative px-6 py-1 text-lg italic text-center '>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                    fill='currentColor'
                    className='w-8 h-8 '
                  >
                    <path d='M232,246.857V16H16V416H54.4ZM48,48H200V233.143L48,377.905Z'></path>
                    <path d='M280,416h38.4L496,246.857V16H280ZM312,48H464V233.143L312,377.905Z'></path>
                  </svg>
                  {studentTestimonial.content}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                    fill='currentColor'
                    className='absolute right-0 w-8 h-8 '
                  >
                    <path d='M280,185.143V416H496V16H457.6ZM464,384H312V198.857L464,54.1Z'></path>
                    <path d='M232,16H193.6L16,185.143V416H232ZM200,384H48V198.857L200,54.1Z'></path>
                  </svg>
                </p>
              </div>
              <div className='flex flex-col items-center justify-center p-8 rounded-b-lg bg-violet-400'>
                <p className='text-xl font-semibold leading-tight'>
                  {studentTestimonial.name}
                </p>
                <p className='text-sm uppercase'>{studentTestimonial.from}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default StudentTestimonials;
