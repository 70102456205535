import React, { useEffect, useState } from "react"; 

const JoinUs = ({ text, link }: { text?: string; link: string }) => {
  useEffect(() => {
    window.location.href = link;
  }, []);
  return (
    <div className='flex justify-center items-center h-screen flex-col text-center'>
      <span className='relative inline-flex'>
        <h1 className='text-4xl md:text-6xl font-bold mb-5 text-gray-600'>
          {text ? text : 'Redirecting to the form!'}
        </h1>
      </span>
      <span className='relative custom-hover inline-flex'>
        <h3 className='text-2xl md:text-xl mb-5 text-gray-400'>
          <a href={ link }>
            Click Here if you aren't redirected automatically.
          </a>
        </h3>
      </span>
    </div>
  );
};

export default JoinUs;
