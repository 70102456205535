import React, { useEffect, useState } from 'react';
import { QUESTIONS } from '../../resources/questions';
import { useNavigate } from 'react-router-dom';
import { meCall } from '../../service/diplomacia-be';

const QuestionPage = () => {
  const [isUser, setIsUser] = useState(true);
  /*const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const me = await meCall();
      if (me.data.success) {
        setIsUser(me.data.success);
      } else navigate('/signin');
    })();
  }, [isUser]);*/

  const [viewQues, setViewQues] = useState<boolean>(false);
  const next: string[] = [...QUESTIONS];
  const [current, setCurrent] = useState<string>(next[0]);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {}, [current]);

  const visitNextQ = () => {
    if ( count == next.length - 1){
      setCount(0);
      setCurrent(next[0]);
      setViewQues(false);
      return;
    }
    if (count < next.length - 1) {
      setCurrent(next[count]);
      setCount(count + 1);
    }
  };

  const visitPrevQ = () => {
    if ( count == 0){
      setViewQues(false);
      return;
    }
    if (count > 0) {
      setCount(count - 1);
      setCurrent(next[count]);
    }
  };
  if(isUser){
    if (!viewQues) { 
      return (
        <div className='Main h-screen bg-gray-900'>
          <div className='bg-primary lg:h-[5.5rem]'></div>
          <div className='flex flex-col items-center text-white h-full'>
            <div className='title text-center font-bold lg:text-6xl lg:mt-40 mt-64 text-4xl'>
              Event Name
            </div>
            <div className='text-center lg:mt-4 mt-2 lg:text-2xl text-xl'>
              Short Description
            </div>
            <div className='text-center lg:w-1/2 w-5/6 text-base text-white text-opacity-75'>
              Lobortis tempor laoreet sit maecenas magnis vivamus fames interdum
              vulputate eget morbi dapibus adipiscing nisl mattis facilisi elit
              pretium sodales ornare
            </div>
            <div className='text-center lg:mt-4 mt-2 lg:text-2xl text-lg'>
              Visit each question and Discuss about it
            </div>
            <div className='mt-8 flex flex-wrap justify-center gap-4'>
              <a
                className='block cursor-pointer w-full rounded-lg bg-primary px-12 py-4 lg:text-xl text-sm font-medium text-white shadow-md hover:bg-primary-cta hover:text-primary sm:w-auto custom-hover'
                onClick={() => setViewQues(true)}
              >
                View Questions
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='Main h-screen bg-gray-900'>
          <div className='bg-primary lg:h-[5.5rem]'></div>
          <div className='flex flex-col items-center text-white h-full'>
            <div className='title text-center font-bold mb-6 lg:text-5xl lg:mt-36 mt-64 text-4xl'>
              Event Name
            </div>
            <div className='block text-left w-4/5 text-2xl font-bold'>
              {' '}
              {count + 1} / {QUESTIONS.length}{' '}
            </div>
            <div className='block text-left w-4/5'> {next[count]} </div>
            <div className='flex item-center mt-8'>
              <a
                className='block cursor-pointer mr-6 w-full text-center rounded-lg bg-gray-600 px-10 py-3 lg:text-xl text-sm font-medium text-white shadow-md hover:bg-primary-cta hover:text-primary sm:w-auto custom-hover'
                onClick={visitPrevQ}
              >
                {count != 0 ? 'Previous' : 'Back'}
              </a>
              <a
                className='block cursor-pointer w-full text-center rounded-lg bg-primary px-10 py-3 lg:text-xl text-sm font-medium text-white shadow-md hover:bg-primary-cta hover:text-primary sm:w-auto custom-hover'
                onClick={visitNextQ}
              >
                {count != next.length - 1 ? 'Next' : 'Finish'}
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
  else{
    return (
    <div className='text-center max-w-xl mx-auto my-60'>
    <div role='status'>
      <svg
        aria-hidden='true'
        className='inline w-10 h-10 mr-2 text-gray-200 animate-spin fill-blue-600'
        viewBox='0 0 100 101'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 220.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
          fill='currentColor'
        />
        <path
          d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
          fill='currentFill'
        />
      </svg>
      <span className='sr-only'>Loading...</span>
    </div>
    <h1 className='text-2xl mt-10 font-bold mb-5 text-gray-600'>
      Loading ...
    </h1>
  </div>);
  }
};

export default QuestionPage;