import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createCert } from '../../service/diplomacia-be';
import Qr from './Qr';

const Form = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [showQr, setShowQr] = useState(false);
  const [qrData, setQrData] = useState<{ qr: string; name: string }>({
    qr: '',
    name: '',
  });
  const [data, setData] = useState<{
    name: string;
    description: string;
    event: string;
    image: string;
  }>({
    name: '',
    description: '',
    event: '',
    image: '',
  });

  const [error, setError] = useState(false);

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { data: certRes } = await createCert({ ...data, date: startDate });
    if (certRes.certData.error) {
      setError(true);
    } else {
      const { qrCodeUrl, name } = certRes.certData;
      setQrData({ qr: qrCodeUrl, name: name });
      setShowQr(true);
    }
  };
  return (
    <>
      {!showQr ? (
        <form onSubmit={onSubmit}>
          <div className='space-y-12'>
            {error ? (
              <div
                className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
                role='alert'
              >
                <strong className='font-bold'>Error!</strong>
                <span className='block sm:inline'> Something went wrong.</span>
              </div>
            ) : null}
            <div className='border-b border-gray-900/10 pb-12'>
              <h2 className='text-2xl font-semibold leading-7 text-gray-900'>
                Certificate Creation Form
              </h2>
              <p className='mt-1 text-sm leading-6 text-gray-600'>
                This information will be displayed publicly so be careful what
                you share.
              </p>

              <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
                <div className='sm:col-span-4'>
                  <label
                    htmlFor='username'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Name
                  </label>
                  <div className='mt-2'>
                    <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
                      <input
                        type='text'
                        name='name'
                        id='name'
                        autoComplete='name'
                        onChange={handleInput}
                        className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                        placeholder='Piyush Duggal'
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className='sm:col-span-4'>
                  <label
                    htmlFor='username'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Event Name
                  </label>
                  <div className='mt-2'>
                    <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
                      <input
                        type='text'
                        name='event'
                        id='event'
                        onChange={handleInput}
                        className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                        placeholder='Diplomacia MUN 1.0'
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className='sm:col-span-4'>
                  {' '}
                  <label
                    htmlFor='username'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Date
                  </label>
                  <div className='mt-2'>
                    <DatePicker
                      name='date'
                      id='date'
                      onChange={(date: Date) => setStartDate(date!)}
                      className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      selected={startDate}
                      required
                    />
                  </div>
                </div>
                <div className='sm:col-span-4'>
                  <label
                    htmlFor='username'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Image (Optional)
                  </label>
                  <div className='mt-2'>
                    <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
                      <input
                        type='text'
                        name='image'
                        id='image'
                        onChange={handleInput}
                        className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                        placeholder='Image URL'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-span-full'>
                  <label
                    htmlFor='about'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Description (Optional)
                  </label>
                  <div className='mt-2'>
                    <textarea
                      id='description'
                      name='description'
                      rows={3}
                      onChange={handleInput}
                      className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      defaultValue={''}
                    />
                  </div>
                  <p className='mt-3 text-sm leading-6 text-gray-600'>
                    Write a few sentences about the receiver.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-6 flex items-center justify-end gap-x-6'>
            <button
              type='button'
              className='text-sm font-semibold leading-6 text-gray-900'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              Save
            </button>
          </div>
        </form>
      ) : (
        <>
          <Qr qr={qrData.qr} name={qrData.name} />
        </>
      )}
    </>
  );
};

export default Form;
