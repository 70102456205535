import {
    arkaBanerjee,
    //mayank,
    //nivedita,
    piyush,
    rupkatha,
    //tamajit,
    Fathima,
    Madhav,
    Vaishnavi,
  } from '../assets';

type MemberListType = {
    name: string;
    position?: string;
    image?: string;
    twitter?: string;
    faceBook?: string;
    linkedIn?: string;
    instagram?: string;
    github?: string;
    email?: string;
}[];

type MemberSectionType = {
    label: string,
    title: string,
    color: string,
    members: MemberListType
}

export const leaders: MemberListType = [
    // {
    //   name: 'Mayank Jaiswal',
    //   position: 'Founder',
    //   image: mayank,
    //   linkedIn: 'https://www.linkedin.com/in/mayank-js/',
    //   instagram: 'https://www.instagram.com/ego.rimor/',
    // },
    {
      name: 'Piyush Duggal',
      position: 'Founder',
      image: piyush,
      linkedIn: 'https://www.linkedin.com/in/piyush-duggal/',
      instagram: 'https://www.instagram.com/piyush_duggal/',
      github: 'https://www.github.com/piyushduggal-source/',
    },
    // {
    //   name: 'Nivedita Jayaswal',
    //   position: 'Founder',
    //   image: nivedita,
    //   instagram: 'https://www.instagram.com/nivoceros/',
    // },
    {
      name: 'Rupkatha Suter',
      position: 'Founder',
      image: rupkatha,
      linkedIn: 'https://www.linkedin.com/in/rupkatha-suter-00b4a4253/',
      // instagram: 'https://www.instagram.com/_rup._.katha_/',
    },
    {
      name: 'Arka Banerjee',
      position: 'Founder',
      image: arkaBanerjee,
      linkedIn: 'https://www.linkedin.com/in/arka-banerjee-440641241/',
      instagram: 'https://www.instagram.com/ravenclaw_iitm/',
    },
    // {
    //   name: 'Tamajit Sadhukhan',
    //   position: 'Founder',
    //   image: tamajit,
    //   linkedIn: 'https://www.linkedin.com/in/tamajit03/',
    //   instagram: 'https://www.instagram.com/tamajit03/',
    // },
];

export const teamMembers: MemberSectionType[] = [
    {
        label: 'MeetTheTeam',
        title: 'Meet The Team', 
        color: 'bg-slate-900',
        members: [
            {
                name: 'A.R. Madhav',
                image: Madhav,
                position: 'Secretary',
                instagram: 'https://www.instagram.com/_._maddy_._82_._/',
                linkedIn: 'https://www.linkedin.com/in/madhav-a-r-706209295/',
            },
            {
                name: 'Fathima Raniya',
                image: Fathima,
                position: 'Deputy Secretary',
                linkedIn: 'https://www.linkedin.com/in/fathima-raniya-1a5779287',
            },
            {
                name: 'Vaishnavi Verma',
                image: Vaishnavi,
                position: 'Student Mentor',
                instagram: 'https://www.instagram.com/its_vaishh.13',
                linkedIn: 'https://www.linkedin.com/in/vaishnavi-verma-987306258',
            },
	]
    },
];
