import React from 'react';
import { BsLinkedin, BsYoutube } from 'react-icons/bs';
import { FiInstagram } from 'react-icons/fi';
import { logoPng } from '../assets';
import { navigationLeft } from './Navbar';
import { setActiveNav } from './Navbar';
import { AiOutlineMail } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const socials = [
  {
    icon: <FiInstagram className='custom-hover' style={{ fontSize: '24px' }} />,
    href: 'https://www.instagram.com/iitm_diplomacia',
  },
  {
    icon: <BsLinkedin className='custom-hover' style={{ fontSize: '22px' }} />,
    href: 'https://www.linkedin.com/company/diplomacia-iitm-bs/',
  },
  {
    icon: <BsYoutube className='custom-hover' style={{ fontSize: '24px' }} />,
    href: 'https://www.youtube.com/@diplomacia_iitm',
  },
  // { icon: <FiTwitter style={iconStyle} />, href: '#', ping: false },
];
const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer id='footer' className='p-6 sm:p-24 bg-gray-900'>
      <div className='md:flex md:justify-between lg:gap-60 md:gap-14'>
        <div className=' mb-6 md:mb-0'>
          <span
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/');
            }}
            className='flex -mt-6 flex-col items-center cursor-pointer'
          >
            <img className='w-48' src={logoPng} />

            {/*

            <a
              className='custom-gradient-2 text-xl font-bold leading-relaxed inline-block mx-4 whitespace-nowrap uppercase'
              href='/'
            >
              <span className='sr-only'>Diplomacia</span>
              Diplomacia
            </a>
            */}
          </span>
        </div>
        <div className='grid grid-cols-2 gap-4 sm:grid-cols-3'>
          <div>
            <h2 className='mb-4 custom-gradient-2 text-[16px] font-semibold uppercase'>
              Navigate
            </h2>
            <ul className='text-gray-400 flex flex-col'>
              {navigationLeft.map((nav, i) => (
                <span className='mr-4' key={i}>
                  <a
                    onClick={() => {
                      /*if (nav.path) {
                        navigate(nav.path);
                      }*/
                      if (nav.path) {
                        nav.name != 'Events'
                          ? setActiveNav(nav.name)
                          : setActiveNav('');
                        navigate(nav.path);
                      }
                      window.location.hash = nav.href;
                      window.scrollTo(0, 0);
                    }}
                    className='cursor-pointer hover:underline'
                  >
                    {nav.name}
                  </a>
                </span>
              ))}
            </ul>
          </div>
          <div>
            <h2 className='mb-4 custom-gradient-2 text-[16px] font-semibold uppercase'>
              Follow us
            </h2>
            <ul className='text-gray-400 flex'>
              {socials.map((social, i) => (
                <span className='mr-4' key={i}>
                  <a href={social.href} target='_blank'>
                    {social.icon}
                  </a>
                </span>
              ))}
            </ul>
          </div>
          <div>
            <h2 className='mb-4 custom-gradient-2 text-[16px] font-semibold  uppercase'>
              Contact Us
            </h2>
            <ul className='text-gray-400'>
              {/*
              <li className='mb-4'>
                IITM BS Office <br />
                3rd floor, ICSR Building, <br />
                Indian Institute of Technology Madras, <br />
                Chennai 600036, Tamil Nadu, INDIA
              </li>
              <a
                href='https://study.iitm.ac.in/ds/'
                target='_blank'
                className='mb-4 flex items-center gap-1 underline break-words'
              >
                <BiWorld />
                https://study.iitm.ac.in/ds/
              </a>
              */}
              <a
                href='mailto:diplomacia@study.iitm.ac.in'
                className='mb-4 flex items-center gap-1 underline'
              >
                <AiOutlineMail />
                <span className='break-keep'>diplomacia@study.iitm.ac.in</span>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <hr className='my-6 sm:mx-auto border-gray-700 lg:my-8' />
      <div className='sm:flex sm:items-center sm:justify-between'>
        <span className='text-sm sm:text-center text-gray-400'>
          © 2023{' '}
          <a
            href='https://https://iitmdiplomacia.in/'
            className='hover:underline'
          >
            Diplomacia | IITM BS
          </a>
          . All Rights Reserved.
        </span>
        <div className='flex mt-4 space-x-6 sm:justify-center sm:mt-0'>
          <span className='text-sm sm:text-center text-gray-400'>
            Made with ❤ by{' '}
            <a
              href='https://www.linkedin.com/in/piyush-duggal'
              className='underline cursor-pointer'
              target='_blank'
            >
              Piyush Duggal
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
