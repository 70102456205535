import React from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import saveSvgAsPng from 'save-svg-as-png';

const Qr = ({ qr, name }: { qr: string; name: string }) => {
  console.log(qr, name);
  const navigate = useNavigate();
  const downloadQR = (qr: string, name: string) => {
    const svg = document.getElementById(qr);
    saveSvgAsPng.saveSvgAsPng(svg, `${name}.png`, {
      scale: 2,
      encoderOptions: 1,
      backgroundColor: 'white',
    });
  };

  return (
    <div className='flex flex-col gap-4 justify-center items-center'>
      <QRCode id={qr} value={qr} />
      <a className='text-xl cursor-pointer text-blue-500 underline' onClick={() => downloadQR(qr, name)}>
        Download QR{' '}
      </a>

      <div className='flex gap-5'>
        <button className='relative custom-hover inline-flex'>
          <a
            className='font-bold cursor-pointer -mx-3 block bg-gray-500/10 rounded-lg py-2 px-3 mr-1 leading-7 text-primary hover:bg-gray-500/20'
            onClick={() => (window.location.href = '/dashboard')}
          >
            Create New QR
          </a>
        </button>
        <button className='relative custom-hover inline-flex'>
          <a
            target='_blank'
            className='font-bold cursor-pointer block bg-primary/90 rounded-lg py-2 px-3 mr-1 leading-7 text-primary-cta transition-colors hover:bg-primary'
            onClick={() => navigate('/')}
          >
            Go Home
          </a>
        </button>
      </div>
    </div>
  );
};

export default Qr;
