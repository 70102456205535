import React from 'react';
import { FAQ_LIST } from '../resources/faq';

const FAQs = () => {
  return (
    <div className='lg:mx-60 md:mx-36 mx-4 my-20' id='FAQ'>
      <div className='text-center max-w-xl mx-auto'>
        <h1 className='text-4xl md:text-5xl font-bold mb-5 text-gray-600'>
          FAQs
        </h1>
        <div className='text-center mb-10'>
          <span className='inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1'></span>
          <span className='inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1'></span>
          <span className='inline-block w-40 h-1 rounded-full bg-indigo-500 ml-2'></span>
          <span className='inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1'></span>
          <span className='inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1'></span>
        </div>
      </div>
      <div className='space-y-4'>
        {FAQ_LIST.map((faq, index) => (
          <details
            key={index}
            className='group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden'
            open={index === 0}
          >
            <summary className='flex cursor-pointer items-center justify-between gap-1.5'>
              <h2 className='text-lg font-medium text-gray-900'>
                {faq.question}
              </h2>

              <span className='shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-45'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                    clipRule='evenodd'
                  />
                </svg>
              </span>
            </summary>

            <p className='mt-4 leading-relaxed text-gray-700'>{faq.answer}</p>
          </details>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
