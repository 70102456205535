type PositionPaper = {
  title: string;
  by: string;
  representing: string;
  description?: string;
  date: string;
  readingTime: number;
  url: string;
};

export const POSITION_PAPERS: PositionPaper[] = [
  {
    title:
      'National Policies and Practices for Regulating AI in Education: Ensuring Ethical and Effective Use of Artificial Intelligence',
    date: 'March 2023',
    by: 'Anushika Dubey',
    representing: 'Kingdom of Norway',
    url: 'https://docs.google.com/document/d/1QxyRa6aS-xPhp4CizrARPmXNbLcpLmUF/edit?usp=sharing&ouid=102296148912832093050&rtpof=true&sd=true',
    readingTime: 3,
  },
  {
    title:
      'National Policies and Practices for Regulating AI in Education: Ensuring Ethical and Effective Use of Artificial Intelligence',
    date: 'March 2023',
    by: 'Advait Vats',
    representing: 'Austria',
    url: 'https://drive.google.com/file/d/1lBE34DYHAzQyrzl6n02sRF4ARc0XLc8E/view?usp=sharing',
    readingTime: 3,
  },
  {
    title:
      'National Policies and Practices for Regulating AI in Education: Ensuring Ethical and Effective Use of Artificial Intelligence',
    date: 'March 2023',
    by: 'Amol Wani',
    representing: 'Slovenia',
    url: 'https://drive.google.com/file/d/10GWhnMs6E1RkVv_T1MKynh3oTrZx_EDi/view',
    readingTime: 3,
  },
];
