import React from 'react';
import { imgSvgs } from '../styles';
import defaultImg from '../assets/defaultAvatar.png';
import Down from './common/Down';
import { BsLinkedin, BsInstagram, BsTwitter, BsFacebook, BsGithub } from 'react-icons/bs';
import { leaders } from '../resources/teamMembers';
import { teamMembers } from '../resources/teamMembers';
import { random } from './utils/utils';

const iconStyle = {
  fontSize: '20px',
};

const cardIconStyle = {
  fontSize: '16px',
}

const Team = () => {
  return (
    <>
      {/*
      <div id='mun' className='-skew-y-3 h-40 -mt-24 w-full bg-sky-100'></div>
       */}
      <div
        id='organisers'
        className='relative snap-center flex justify-center mx-auto px-6 lg:px-52 bg-sky-100 pt-16'
      >
        <section className='mb-18 text-gray-800 text-center'>
          <h1 className='lg:text-5xl mt-16 mb-2 text-3xl font-extrabold'>
            We're changing the
          </h1>
          <h1 className='lg:text-5xl pb-8 text-3xl font-extrabold'>
            whole game.
          </h1>
          <div className='grid place-items-center md:grid-cols-3 gap-x-6 gap-y-6 lg:grid-cols-3 lg:gap-x-12'>
            {leaders.map((member, i) => (
              <div
                data-aos='zoom-in-up'
                data-aos-delay={i * 50}
                key={member.name}
                className='mb-6 lg:mb-0 max-w-[260px]'
              >
                <div className='bg-white block rounded-lg shadow-lg min-h-[420px]'>
                  <div className='relative overflow-hidden h-60 bg-no-repeat bg-cover'>
                    {member.image ? (
                      <>
                        <img
                          className='w-full rounded-t-lg'
                          src={member.image}
                        />
                      </>
                    ) : (
                      <img className='w-full rounded-t-lg' src={defaultImg} />
                    )}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: imgSvgs[random(3)],
                      }}
                    ></div>
                  </div>
                  <div className='p-6'>
                    <h5 className='text-lg font-bold mb-4 cursor-pointer custom-hover'>
                      {member.name}
                    </h5>
                    <p className='text-gray-500 mb-4'>{member.position}</p>
                    <ul className='list-inside flex justify-center border-t-2 mx-4 pt-2'>
                      {member.linkedIn && (
                        <a
                          href={member.linkedIn}
                          target='_blank'
                          className='px-2 text-blue-500 custom-hover'
                        >
                          <BsLinkedin style={iconStyle} />
                        </a>
                      )}
                      {member.twitter && (
                        <a
                          href={member.twitter}
                          target='_blank'
                          className='px-2 text-blue-500 custom-hover'
                        >
                          <BsTwitter style={iconStyle} />
                        </a>
                      )}
                      {member.faceBook && (
                        <a
                          href={member.faceBook}
                          target='_blank'
                          className='px-2 text-blue-500 custom-hover'
                        >
                          <BsFacebook style={iconStyle} />
                        </a>
                      )}
                      {member.instagram && (
                        <a
                          href={member.instagram}
                          target='_blank'
                          className='px-2 text-blue-500 custom-hover'
                        >
                          <BsInstagram style={iconStyle} />
                        </a>
                      )}
                      {member.github && (
                        <a
                          href={member.github}
                          target='_blank'
                          className='px-2 text-blue-500 custom-hover'
                        >
                          <BsGithub style={iconStyle} />
                        </a>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Down href={ teamMembers[0].label } />
        </section>
      </div>
      <div className='snap-center w-full -mt-24 bg-sky-100 h-[40px]'></div>
      <div className= {`-skew-y-2 snap-center h-40 w-full ${teamMembers[0].color} min-h-[0px]`} ></div>
      <div className= {`snap-center w-full -mt-12 ${teamMembers[0].color} pt-[10px] text-white`} >
        {teamMembers.map((field, i) => (
          <div 
            data-aos='fade-up'
            data-aos-delay={i * 50}
            key={field.label}
            className= {`flex justify-center justify-center w-full ${field.color} items-center mt-10 flex-col`}
          >
            <div id={field.label}><h3 className='mt-10 text-center lg:text-3xl mb-12 text-3xl font-extrabold uppercase' id={field.label}> {field.title} </h3> </div>
            <div className='grid place-items-center md:grid-cols-3 gap-x-6 gap-y-6 lg:grid-cols-3 lg:gap-x-12 place-content-center'>
              {field.members.map((member, i) => (
                <div 
                  data-aos='fade-up'
                  data-aos-delay={i * 50}
                  key={ member.name }
                  className='w-56 h-76 rounded-xl overflow-hidden text-center'
                >
                  <div
                    //src={ member.image }
                    style={{
                      backgroundImage: `url('${ member.image }')`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                    className='w-56 h-80'
                  ></div>
                  <div className='-mt-56 relative h-[210px] bg-gradient-to-t from-[#000000e2] pt-[130px]'>
                    <div className='text-lg font-bold cursor-pointer custom-hover'> { member.name } </div>
                    <div className='text-[12px] font-bold cursor-pointer custom-hover'> { member.position } </div>
                    { /*<a className='text-[12px] block cursor-pointer custom-hover -mt-1' target='_blank' href={ `mailto:${ member.email }` }> { member.email } </a> */ }
                    <ul className='list-inside flex justify-center mx-2 pt-1'>
                      {member.linkedIn && (
                        <a
                          href={member.linkedIn}
                          target='_blank'
                          className='px-1 text-white custom-hover'
                        >
                          <BsLinkedin style={cardIconStyle} />
                        </a>
                      )}
                      {member.twitter && (
                        <a
                          href={member.twitter}
                          target='_blank'
                          className='px-1 text-white custom-hover'
                        >
                          <BsTwitter style={cardIconStyle} />
                        </a>
                      )}
                      {member.faceBook && (
                        <a
                          href={member.faceBook}
                          target='_blank'
                          className='px-1 text-white custom-hover'
                        >
                          <BsFacebook style={cardIconStyle} />
                        </a>
                      )}
                      {member.instagram && (
                        <a
                          href={member.instagram}
                          target='_blank'
                          className='px-1 text-white custom-hover'
                        >
                          <BsInstagram style={cardIconStyle} />
                        </a>
                      )}
                      {member.github && (
                        <a
                          href={member.github}
                          target='_blank'
                          className='px-1 text-white custom-hover'
                        >
                          <BsGithub style={cardIconStyle} />
                        </a>
                      )}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
            <Down href={ teamMembers[i+1]? teamMembers[i+1].label : 'footer' } />
          </div>
        ))}
      </div>
    </>
  );
};

export default Team;
