import React from 'react';
import { POSITION_PAPERS } from '../../resources/activities';

const Activities = () => {
  return (
    <div className='bg-gray-900 text-white'>
      <section className=''>
        <div className='mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center'>
          <div className='mx-auto max-w-xl text-center'>
            <h1 className='text-3xl font-extrabold sm:text-5xl custom-gradient-2'>
              Explore the Student Activities
            </h1>

            <p className='mt-4 sm:text-xl/relaxed'>
              Get Involved and Make the Most of Your College Experience:
              Discover the Wide Range of Student Activities
            </p>

            <div className='mt-8 flex flex-wrap justify-center gap-4'>
              <a
                className='block w-full rounded-lg bg-primary px-12 py-3 lg:text-xl text-sm font-medium text-white shadow-md hover:bg-primary-cta hover:text-primary sm:w-auto custom-hover'
                href='#activities'
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          id='activities'
          className='grid justify-center md:grid-cols-3 gap-x-6 gap-y-6 lg:grid-cols-3 lg:gap-x-8 pb-12 mx-8'
        >
          {POSITION_PAPERS.map((position_paper, index) => (
            <a
              key={index}
              href={position_paper.url}
              className='relative block overflow-hidden rounded-lg bg-white border shadow-lg border-gray-100 p-4 sm:p-6 lg:p-8'
            >
              <span className='absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600'></span>

              <div className='sm:flex sm:justify-between sm:gap-4'>
                <div>
                  <h3 className='text-lg font-bold text-gray-900 sm:text-xl'>
                    {position_paper.title}
                  </h3>

                  <p className='mt-1 text-xs font-medium text-gray-600'>
                    By <span className='font-bold'>{position_paper.by}</span>,
                    representing{' '}
                    <span className='font-bold'>
                      {position_paper.representing}
                    </span>
                  </p>
                </div>
              </div>

              <div className='mt-4'>
                <p className='max-w-[40ch] text-sm text-gray-500'>
                  {position_paper.description}
                </p>
              </div>

              <dl className='mt-6 flex gap-4 sm:gap-6'>
                <div className='flex flex-col-reverse'>
                  <dt className='text-sm font-medium text-gray-600'>
                    Published
                  </dt>
                  <dd className='text-xs text-gray-500'>
                    {position_paper.date}
                  </dd>
                </div>

                <div className='flex flex-col-reverse'>
                  <dt className='text-sm font-medium text-gray-600'>
                    Reading time
                  </dt>
                  <dd className='text-xs text-gray-500'>
                    {position_paper.readingTime} minute
                  </dd>
                </div>
              </dl>
            </a>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Activities;
