import React from 'react';
import { logoBlack } from '../assets';
import Down from './common/Down';

export const AboutSection = () => {
  const missions = [
    'Honesty-Humility',
    'Emotionality',
    'Extraversion',
    'Agreeableness',
    'Conscientiousness',
    'Openness to Experience',
  ];
  return (
    <div className='2xl:container flex flex-col items-center mt-16 span-center 2xl:mx-auto lg:pt-12 lg:px-20 md:py-12 md:px-6 py-9 px-4 bg-sky-100'>
      <div className='flex mb-6 text-center justify-center'>
        <h1 className='text-3xl text-center sm:text-4xl font-extrabold text-primary tracking-tight'>
          About Diplomacia
        </h1>
      </div>
      <img src={logoBlack} className='h-80 w-80 mb-4 rounded-3xl' />
      <div className='flex flex-col items-center w-full'>
        <h2 className='font-bold text-center lg:text-2xl text-xl lg:leading-9 leading-7 text-gray-800'>
          Our mission
        </h2>
        <p className='font-normal text-center text-base leading-6 text-gray-600 mt-6 lg:w-[70%]'>
          Welcome to Diplomacia - a society that goes beyond just honing your
          soft skills. It's a place where you'll discover your true potential
          and transform into a confident and capable leader. Here, we understand
          that the journey of personal growth is not always easy, but it's one
          that's worth it. Our community is built on the foundation of
          supporting each other and providing a safe space for growth. We
          believe that through Model UNs, Parliamentary Debates, Public Speaking
          Bootcamps, and other mock practices, we can help you develop the
          skills necessary to succeed in life. At Diplomacia, we're not just
          teaching you how to speak in public or how to negotiate. We're shaping
          you into a well-rounded individual who's empathetic, resilient, and
          compassionate. We're here to push you out of your comfort zone, to
          help you discover what you're truly capable of, and to guide you
          towards your goals. So, come join us, and let's embark on this journey
          of growth and discovery together. Let's transform your life, one skill
          at a time.
        </p>
        <p className='font-normal text-center text-base leading-6 text-gray-600 mt-6 w-[70%]'>
          Started in January 2023, what started with a mere group effort now
          targets the whole 17K+ students of our degree program.
        </p>
      </div>
      <div className='flex flex-col md:gap-10 gap-16 justify-between mt-10'>
        <div className='w-full text-blue-50'>
          <div className='text-gray-800 text-center font-bold mb-6 text-xl lg:text-2xl'>
            We have 6 Directions of Our Vision on Personality & Soft Skill
            Development which represents & symbolize the 6 key dimensions of
            human personality from HEXACO model by Ashton & Lee:
          </div>
          <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6'>
            {missions.map((mission, i) => (
              <div
                key={i}
                data-aos='fade-up'
                data-aos-delay={i * 200}
                className='flex items-center justify-center py-4 px-6 text-center border-2 border-slate-300'
              >
                <p className='font-semibold text-xl text-primary/80'>
                  {mission}
                </p>
              </div>
            ))}
            {/* <!-- Team Card --> */}
          </div>
        </div>
        <div>
          <h2 className='font-bold lg:text-2xl text-xl lg:leading-9 leading-7 text-gray-800 mb-8'>
            Our objectives
          </h2>
          <ul className='flex font-medium flex-col text-center gap-4'>
            <li
              data-aos='fade-right'
              aria-current='true'
              className='block w-full cursor-pointer rounded-lg bg-primary/20 p-4'
            >
              To provide a platform for students to develop soft skills that are
              essential for their personal and professional growth.
            </li>
            <li
              data-aos='fade-right'
              data-aos-delay='300'
              aria-current='true'
              className='block w-full cursor-pointer rounded-lg bg-primary/20 p-4'
            >
              To foster a sense of community among students who are interested
              in developing their soft skills.
            </li>
            <li
              data-aos='fade-right'
              data-aos-delay='500'
              aria-current='true'
              className='block w-full cursor-pointer rounded-lg bg-primary/20 p-4'
            >
              To prepare students for Model United Nations (MUN) conferences,
              international conferences and other debate-related competitions.
            </li>
            <li
              data-aos='fade-right'
              data-aos-delay='700'
              aria-current='true'
              className='block w-full cursor-pointer rounded-lg bg-primary/20 p-4'
            >
              To promote an environment of healthy debate and discussion among
              students.
            </li>
          </ul>
        </div>
      </div>
      <Down href='footer' />
    </div>
  );
};

export default AboutSection;
