import axios from 'axios';
axios.defaults.withCredentials = true;
const diplomaciaBe = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  headers: {
    'Content-type': 'application/json',
    'x-diplo-api': process.env.REACT_APP_BE_API_KEY,
  },
  withCredentials: true,
});

type Response = {
  success: boolean;
};

export const authUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  console.log(`\nEntering authUser with data: ${email} ${password}`);
  return await diplomaciaBe.post<Response>(
    '/login',
    { email, password },
    { withCredentials: true },
  );
};

export const meCall = async () => {
  console.log('\nEntering meCall');
  return await diplomaciaBe.get<{ success: boolean }>('/me', {
    withCredentials: true,
  });
};

export type CertificateRes = {
  name: string;
  date: Date;
  event: string;
  description?: string;
  image?: string;
};

export type CertRes = {
  name: string;
  qrCodeUrl: string;
  error?: string;
};

export type GetCertRes = {
  certData: CertificateRes & { qrCodeSlang: string };
  error?: string;
};

export const createCert = async (certData: CertificateRes) => {
  return await diplomaciaBe.post<{ certData: CertRes }>(
    '/cvs-service/api/createCert',
    certData,
  );
};

export const getCert = async (id: string) => {
  return await diplomaciaBe.get<GetCertRes>(`/cvs-service/api/getcert/${id}`);
};
