import React from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { defaultImg } from '../assets';
import { testimonialsList } from '../resources/testimonials';
import Down from './common/Down';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const Testimonials = () => {
  return (
    <>
      <div id='mun' className='-skew-y-3 h-40 -mt-32 w-full bg-green-300'></div>
      <section id='testimonials' className='bg-green-300 pb-2 -mt-24 relative'>
        <div className='mx-auto max-w-screen-xl px-4 py-4 sm:px-6 lg:px-8'>
          <h2 className='text-center text-gray-700 text-4xl mb-10 font-bold tracking-tight sm:text-5xl'>
            Testimonials
          </h2>
          <Swiper
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1.5,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination, Autoplay]}
            spaceBetween={50}
          >
            <div className='swiper-container mt-4 !overflow-hidden'>
              <div className='swiper-wrapper flex justify-center'>
                {testimonialsList.map((testimonial, i) => (
                  <SwiperSlide key={i}>
                    <blockquote
                      key={testimonial.name}
                      className='rounded-lg bg-gray-100 p-8 h-64 lg:h-60'
                    >
                      <div className='flex items-center'>
                        {testimonial.image ? (
                          <img
                            alt='Man'
                            src={testimonial.image}
                            className='h-16 w-16 rounded-full object-cover'
                          />
                        ) : (
                          <img
                            alt='Man'
                            src={defaultImg}
                            className='h-16 w-16 rounded-full object-cover'
                          />
                        )}

                        <div className='ml-4'>
                          <div className='flex justify-center gap-0.5 text-green-500'>
                            {new Array(testimonial.noOfStarts).map((val) => (
                              <div
                                key={val}
                                dangerouslySetInnerHTML={{
                                  __html: `
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='h-4 w-4'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                  >
                                    <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />{' '}
                                  </svg>`,
                                }}
                              ></div>
                            ))}
                          </div>
                          <p className='mt-1 text-lg font-medium text-gray-700'>
                            {testimonial.name}
                          </p>
                          <p className='mt-1 text-xs font-medium text-gray-500'>
                            {testimonial.post}
                          </p>
                        </div>
                      </div>
                      <p className='mt-4 text-gray-500'>
                        {testimonial.content}
                      </p>
                    </blockquote>
                  </SwiperSlide>
                ))}
              </div>
            </div>
          </Swiper>
        </div>
        <Down href='studentTestimonials' />
      </section>
    </>
  );
};

export default Testimonials;
