import React from 'react';
import { useNavigate } from 'react-router-dom';

const F404 = () => {
  const navigate = useNavigate();
  return (
    <div className='text-center max-w-xl mx-auto my-60'>
      <h1 className='text-4xl md:text-7xl font-bold mb-5 text-gray-600'>
        404 page not found
      </h1>
      <span className='relative custom-hover inline-flex'>
        <a
          onClick={() => navigate('/')}
          className='font-bold mt-2 cursor-pointer block bg-primary/90 rounded-lg py-3 px-7 mr-1 leading-7 text-primary-cta transition-colors hover:bg-primary'
        >
          Go to home page
        </a>
      </span>
    </div>
  );
};

export default F404;
