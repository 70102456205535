import { mrSyan, noarGilion, varunR, vikramDoraiswami } from '../assets';

type TestimonialsL = {
  name: string;
  post: string;
  content: string;
  noOfStarts: number;
  image: string;
  insta?: string;
  youtube?: string;
}[];

export const testimonialsList: TestimonialsL = [
  {
    name: 'Mr. Sayan Banerjee',
    post: 'Advocate, Calcutta High Court',
    content:
      'Wish you all the best for the future, try to organize this kind of seminar in a offline mode, approach advocates, MP, eminent journalist or civil servants',
    noOfStarts: 5,
    image: mrSyan,
  },
  {
    name: 'H.E. Vikram Doraiswami',
    post: 'High Commissioner of India in United Kingdom',
    content:
      'It was a pleasure to interact with the inquisitive members of diplomacia society of IIT Madras. I wish them all the best for their future endeavours',
    noOfStarts: 5,
    image: vikramDoraiswami,
  },
  {
    name: 'H.E. Naor Gilion',
    post: 'Ambassador of Israel to India',
    content:
      'I was very happy with the interaction/talk yesterday. It was of course, great cooperating with you',
    noOfStarts: 5,
    image: noarGilion,
  },
  {
    name: 'Varun R',
    post: 'Founder and Marketer at 3X',
    content:
      'It was a fantastic event with great coordination, kudos to the entire team. Looking forward to further interactions in the future',
    noOfStarts: 5,
    image: varunR,
  },
  // {
  //   name: 'Dr. Ralf Heckner',
  //   post: 'Ambassador, Embassy of Switzerland in India',
  //   content: 'Wishes you all the best for the event.',
  //   noOfStarts: 5,
  //   image: '',
  // },
];

type StudentTestimonialsL = {
  name: string;
  from: string;
  event: string;
  content: string;
}[];

export const studentTestimonials: StudentTestimonialsL = [
  {
    name: 'Nairhritya',
    event: 'Nation’s Notion',
    from: 'Kanpur, Uttar Pradesh',
    content:
      'This was my first time participating in a Position paper writing event. It was thought-provoking, right from brainstorming the topic with the perspective of a country, to looking into the challenges and issues the country faces while formulating the policies. Content provided by organizers was very helpful.',
  },
  {
    name: 'Revanth Rajeev',
    event: 'Nation’s Notion',
    from: 'Mysore, Karnataka',
    content:
      'It is always encouraging to see organizations like the Diplomacia Society working diligently to educate and engage individuals in understanding the rules and procedures of the United Nations. Participating in these events can help individuals develop skills in negotiation, diplomacy, and consensus-building.',
  },
  {
    name: 'Samriddhi Kashyap',
    event: 'Diplomacia 2.0',
    from: 'Lucknow, Uttar Pradesh',
    content:
      'The MUN session was very insightful and made absolute sense in terms of the Principles and their application.It gave us the opportunity to upskill our critical thinking and oratory skills and I am thankful for having the opportunity to attend the session(s) conducted by the Diplomacia society.',
  },
  {
    name: 'Aditya Siddharth Jyoti',
    event: 'Diplomacia 2.0',
    from: 'Bhubaneswar, Odisha',
    content:
      "Got to learn a whole bunch about MUN and felt uplifted in my interaction skills. I didn't expect it to be this awesome. I was new to MUN, but now I like it, and hope to learn a lot more in the next MUN .I am waiting eagerly.",
  },
  {
    name: 'Shivaditya Bhattacharya',
    event: 'Diplomacia 2.0',
    from: 'Hooghly, West Bengal',
    content:
      'It was a great experience. It was my first time, I was still able to make a great use of this opportunity and I have a lot to take away from this.',
  },
  // {
  //   name: 'Vishal Karale',
  //   event: "Diplomacia 2.0",
  //   from: 'Delhi',
  //   content:
  //     'Got to learn, a whole bunch about MUN and, felt uplifted in my interaction skills.',
  // },
  {
    name: 'Swarn Gaurav Verma',
    event: 'Diplomacia 1.0',
    from: 'Bareilly',
    content:
      "It would be very nice if more events of this kinds are conducted at regular intervals, because earlier I wasn't aware of anything like this, but when I saw this I was amazed and I got to know so many ideas and the workshop was an amazing one, got to learn so many things, waiting for more events from Diplomacia.",
  },
  {
    name: 'Chatrapal Singh Rathore',
    event: 'Diplomacia 1.0',
    from: 'Jaipur, Rajasthan',
    content:
      'The event experience turned out to be a very pleasant one with many learning opportunities. The interaction with various members especially was a very good thing. The workshop too helped brush up many aspects, Would definitely want to attend more such events in the future.',
  },
  {
    name: 'Vishal Karale',
    event: 'Diplomacia 1.0',
    from: 'Delhi',
    content:
      'Great learning experience. Became aware about my strengths and weaknesses with respect to debate. Would love to participate again.',
  },
  // {
  //   name: 'Shubhangi Shukla',
  //   event: "Diplomacia 1.0",
  //   from: 'Ahmedabad, Gujarat',
  //   content: 'Amazing meeting new and like minded people and sharing ideas.',
  // },
  // {
  //   name: 'Shivam Anand',
  //   event: "Diplomacia 1.0",
  //   from: 'Jamui, Bihar',
  //   content:
  //     'It was a good experience. There are rooms for improvement. Met new people, learnt to work on surprising topics with new people in limited time.',
  // },
];
