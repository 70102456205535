import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logoPng } from '../assets';
import Down from './common/Down';

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className='relative snap-center pt-16 pb-24 flex content-center items-center justify-center'>
      <div
        className='absolute top-0 w-full h-full bg-center bg-cover'
        style={{
          backgroundImage: "url('https://i.imgur.com/otf9Hhc.jpg')",
        }}
      >
        <span
          id='blackOverlay'
          className='w-full h-full absolute opacity-75 bg-black'
        ></span>
      </div>
      <main data-aos='fade-up'>
        <div className='relative px-6 lg:px-8'>
          <div className='mx-auto max-w-5xl pt-6'>
            <div className='flex justify-center'>
              <img className='w-72' src={logoPng} />
            </div>
            <div className='text-center'>
              <h1 className='text-2xl decoration-solid text-white font-bold tracking-tight lg:text-4xl'>
                <span className='font-extrabold custom-gradient-2 text-4xl lg:text-5xl'>
                  DIPLOMACIA - IIT MADRAS BS
                  <br />
                </span>{' '}
                where technology meets diplomacy
              </h1>{' '}
              <p className='mt-3 text-[20px] leading-8 text-white'>
                Our goal is to empower our members to be effective leaders in
                the world of international relations.
              </p>
              <div className='sm:mb-4 flex justify-center'>
                <div className='relative rounded-full py-1 my-5 px-3 text-base  text-white leading-6 ring-1 ring-custBlue-40 hover:ring-custBlue'>
                  Check student activities:&nbsp;
                  <a
                    onClick={() => navigate('/activities')}
                    className='font-semibold text-primary-cta underline cursor-pointer'
                    target='_blank'
                  >
                    <span className='absolute inset-0' aria-hidden='true' />
                    here
                  </a>
                </div>
              </div>
              <button className='relative custom-hover inline-flex'>
                <a
                  target='_blank'
                  className='font-bold cursor-pointer block bg-primary/90 rounded-lg py-3 px-7 mr-1 leading-7 text-primary-cta transition-colors hover:bg-primary'
                  onClick={() => navigate('/join-us')}
                >
                  Join Us
                </a>
              </button>
              <Down href='mun' />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HeroSection;
