import React from 'react';
import { logoMun } from '../assets';
import { useCountdown } from '../hooks/useCountdown';
import Down from './common/Down';

const LogoSection = () => {
  // target date is 27th May 2023 at 9AM
  const targetDate = new Date('2023-05-27T09:00:00');
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  return (
    <>
      <div
        id='mun'
        className='-skew-y-3 snap-center h-40 -mt-24 w-full bg-slate-900'
      ></div>
      <div className='relative pt-6 bg-slate-900 w-full -mt-24 z-20 pb-2'>
        {/*<p className='flex mb-2 mt-3 text-lg leading-6 font-semibold justify-center text-sky-400'>
          Conference begins in
        </p>*/}
        <div className='flex justify-center items-center'>
          <h1 className='text-4xl text-center sm:text-4xl font-extrabold text-slate-200 tracking-tight'>
            Our events
          </h1>
        </div>
        <div className='flex flex-col lg:flex-row justify-center lg:gap-x-32 items-center py-10'>
          <img data-aos='fade-up' className='w-80' src={logoMun} />
          {/*<img data-aos="fade-up" data-aos-delay="100" className='w-56 sm:w-72' src={logoMic} />*/}
        </div>
        {/* <h5 className='flex mb-2 mt-3 text-4xl leading-6 font-semibold justify-center text-slate-200'>
          Agenda:
        </h5>
        <p className='flex mb-2 mt-3 text-2xl font-semibold text-center justify-center text-primary-cta'>
          Analyzing the Impact of AI and Automation on Labor Markets including
          job displacement, <br />
          skill gaps and the need for retaining and reskilling.
        </p>
        {days + hours + minutes + seconds <= 0 ? (
          <h4 className='flex mb-2 mt-3 text-5xl font-semibold text-center justify-center text-primary-cta'>
            MUN is live!!!
          </h4>
        ) : (
          <p className='flex mb-2 mt-6 text-4xl font-semibold text-center justify-center text-primary-cta'>
            {days}d {hours}h {minutes}m {seconds}s
          </p>
        )} */}

        <Down href='Events' white={false} />
      </div>
    </>
  );
};

export default LogoSection;
