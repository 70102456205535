type FAQ = {
  question: string;
  answer: string;
};

export const FAQ_LIST: FAQ[] = [
  {
    question: '1. How the conference will be conducted?',
    answer:
      'IITM Diplomacia MUN 2023 will be conducted offline at the Indian Institute of Technology Madras Campus.',
  },
  {
    question: '2. Do I need to have prior experience?',
    answer: 'No prior experience will be necessary for the conference.',
  },
  {
    question: '3. What are the rules?',
    answer:
      'The rules are mentioned in the Rules and Procedure section of the website.',
  },
  {
    question: '4. Will the background guide be provided?',
    answer:
      'Yes, the study guide will be made available to all the delegates 2 weeks prior the conference dates.',
  },
  {
    question: '5. What is the dress code for the conference?',
    answer: 'Day 1: Western Formals and day 2: Indian Traditionals',
  },
  {
    question: '6. Is there any age restriction?',
    answer: 'No, anyone and everyone can participate.',
  },
  {
    question:
      '7. Do I get a participation certificate for attending the Paradox MUN?',
    answer:
      'Yes, all the participants will receive a certificate of participation.',
  },
  {
    question:
      '8. Is it ok if my English is not perfect? What level does my English need to be?',
    answer:
      'Yes, however your English needs to be good enough to hold a conversation and be able to understand the discussion. Intermediate level should be fine.',
  },
  {
    question: '9. Is the applications for Executive Board open?',
    answer:
      'Regarding EB applications, please drop a mail at 22f3001864@ds.study.iitm.ac.in',
  },
  {
    question: '10. How do I register for Paradox MUN?',
    answer:
      'In order to register for the Paradox MUN conference click on this link on the website: https://iitmparadox.org/e/diplomaciamun',
  },
  {
    question:
      '11. Can faculty advisors/other students attend committee sessions?',
    answer:
      'They can be observers/visit the committee sessions. However, it is solely on the discretion of the Chair.',
  },
];
